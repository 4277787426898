export function FlatPickrToTimestamp(date) {
  const parts = date.split('/')
  const dateIni = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`)
  return dateIni.getTime() / 1000
}
export function TimestampToFlatPickr(timestamp) {
  const dateIni = new Date(timestamp * 1000)
  let output = ''
  if (dateIni && timestamp && timestamp !== 'null') {
    output = `${dateIni.getDate()}/${dateIni.getMonth() + 1}/${dateIni.getFullYear()}`
  }
  return output
}
export function TimestampToFlatPickrOnlyYear(timestamp) {
  const dateIni = new Date(timestamp * 1000)
  let output = ''
  if (dateIni && timestamp && timestamp !== 'null') {
    output = `${dateIni.getFullYear()}`
  }
  return output
}
export function FlatPickrToTimestampWithTime(date) {
  const parts = date.split('/')
  const dateIni = new Date(`${parts[1]}/${parts[0]}/${parts[2]}`)
  return dateIni.getTime() / 1000
}
export function TimestampToFlatPickrWithTime(timestamp) {
  const dateIni = new Date(timestamp * 1000)
  let output = ''
  if (dateIni && timestamp && timestamp !== 'null') {
    output = `${dateIni.getDate()}/${dateIni.getMonth() + 1}/${dateIni.getFullYear()} ${(dateIni.getHours() < 10) ? '0' : ''}${dateIni.getHours()}:${(dateIni.getMinutes() < 10) ? '0' : ''}${dateIni.getMinutes()}`
  }
  return output
}
export function TimestampToFlatPickrOnlyTime(timestamp) {
  const dateIni = new Date(timestamp * 1000)
  let output = ''
  if (dateIni && timestamp && timestamp !== 'null') {
    output = `${(dateIni.getHours() < 10) ? '0' : ''}${dateIni.getHours()}:${(dateIni.getMinutes() < 10) ? '0' : ''}${dateIni.getMinutes()}`
  }
  return output
}
